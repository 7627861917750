<template>
  <base-form-report>
    <template v-slot:default>
      <div class="my-5 text--small">
        <DatePickerSelectFromTo
          :dates="datePicker"
          :dateFrom="datePicker[0].date"
          :dateTo="datePicker[1].date"
          @change="changeDate"
          :attrTagInput="{
            small: true,
            class: 'btn--date mr-5',
            type: 'btn',
          }"
          :funFormatTitle="formatDate"
          :icon="true"
          @reset="changeDate"
        ></DatePickerSelectFromTo>

        <div class="d-flex align-center mb-3 text--dark">
          {{ $t("common.facility") }}
          <v-select
            :items="facilityList"
            item-text="name"
            item-value="id"
            v-model="facilitySelected"
            dense
            small
            outlined
            color="var(--text__gray)"
            hide-details
            class="v-select-custom ml-1 text--dark facility-list"
          ></v-select>
        </div>

        <v-btn
          v-for="item in btnList"
          :key="item.type"
          :disabled="!checkPerUser"
          @click="pmsCsvReport(item.type)"
          width="250"
          class="mb-3 d-block"
          color="var(--text__primary)"
          outlined
        >
          {{ item.text }}
        </v-btn>
      </div>
    </template>
  </base-form-report>
</template>

<script>
import BaseFormReport from '../../../components/FormReport/BaseFormReport'
import { formatDate, handlErrorView } from '@/constants/functions'
import { PMS_CSV_REPORT } from '@/api/graphql/formReport/pms-csv-report'
import { FACILITY_LIST } from '@/api/graphql/facility-settings'
import { addMonths } from '@/utils/handleDate'
import { downloadFile } from '@/utils/downloadFile'
import { checkPermissionUserCurrent } from '@/utils/permissions'
import { mapMutations } from 'vuex'
import gql from 'graphql-tag'
import DatePickerSelectFromTo from '@/components/Input/datePicker/DatePickerSelectFromTo.vue'

export default {
  name: 'OtherCSV',
  data () {
    return {
      checkPerUser: checkPermissionUserCurrent(this.$router.currentRoute),
      menuDatePicker: [],
      datePicker: [
        { label: '開始', id: 1, date: new Date().toISOString().substr(0, 10) },
        { label: '終了', id: 2, date: new Date().toISOString().substr(0, 10) }
      ],
      facilityList: [
        {
          name: '全て',
          id: null,
          type: null
        }
      ],
      facilitySelected: null,
      typeFile: null,
      btnList: [
        {
          text: '日報作成用CSV出力',
          type: 'DailyReport'
        },
        {
          text: '宿泊集計をCSV出力',
          type: 'BookReport'
        },
        {
          text: 'キャンセル集計をCSV出力',
          type: 'CancelReport'
        },
        {
          text: '空室通知集計をCSV出力',
          type: 'WaitListReport'
        },
        {
          text: '貸出品利用一覧',
          type: 'RentalItemReport'
        },
        {
          text: '施設請求データ一覧(縦)をCSV出力',
          type: 'CreditCardPaymentSummaryReport'
        },
        {
          text: '施設請求データ一覧(横)をCSV出力',
          type: 'FacilityInvoiceReport'
        },
        {
          text: '施設予約一覧在庫データをCSV出力',
          type: 'FacilityInventoryReport'
        },
        {
          text: '宿泊集計(権利分含む)をCSV出力',
          type: 'BookReportWithAssets'
        },
      ]
    }
  },
  mounted () {
    this.getFacilityList()
  },
  methods: {
    addMonths,
    formatDate,
    downloadFile,
    ...mapMutations(['setLoadingOverlayHide', 'setLoadingOverlayShow']),
    async pmsCsvReport (type) {
      this.setLoadingOverlayShow()
      await this.$apollo
        .mutate({
          mutation: PMS_CSV_REPORT,
          variables: {
            fromDate: this.datePicker[0].date,
            toDate: this.datePicker[1].date,
            facilityId: parseInt(this.facilitySelected),
            type
          }
        })
        .then((data) => {
          this.setLoadingOverlayHide()
          const urlList = data.data.pmsCsvReport.files
          if (urlList.length > 0) {
            for (let i = 0; i < urlList.length; i++) {
              this.downloadFile(urlList[i].downloadUrl, urlList[i].fileName)
            }
          }
        })
        .catch(async (error) => {
          this.setLoadingOverlayHide()
          const errorTmp = await handlErrorView(error.graphQLErrors)
          if (errorTmp) {
            this.$store.commit('setAlertError', errorTmp)
          }
          console.error(error)
        })
    },
    async changeDate (item) {
      if (item.id === 1) {
        this.datePicker[0].date = item.date
      } else {
        this.datePicker[1].date = item.date
      }
    },

    async getFacilityList () {
      await this.$apollo
        .query({
          query: gql`
            ${FACILITY_LIST}
          `
        })
        .then((data) => {
          this.facilityList = this.facilityList.concat(data.data.facilityList)
        })
        .catch((error) => {
          console.error(error)
        })
    }
  },
  components: {
    BaseFormReport,
    DatePickerSelectFromTo
  }
}
</script>

<style scoped lang="scss">
::v-deep {
  .v-select-custom {
    input::placeholder {
      color: #000000 !important;
    }
    .v-select__selection--comma {
      color: #000000 !important;
    }
  }
}
</style>
